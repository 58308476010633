import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import makeStyles from "@mui/styles/makeStyles"
import React from "react"
// import TableHead from '@mui/material/TableHead';
import Paper from "@mui/material/Paper"
import TableRow from "@mui/material/TableRow"

const useStyles = makeStyles((theme) => ({
  table: {},
}))

export default function MyTable({ coordinates: { x, y } }) {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell>X: </TableCell>
            <TableCell>{x}</TableCell>
            <TableCell>Y: </TableCell>
            <TableCell>{y}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
