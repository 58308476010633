import makeStyles from "@mui/styles/makeStyles"
import React, { createRef, useEffect, useState } from "react"
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import IconButton from '@material-ui/core/IconButton';
import Button from "@mui/material/Button"
import { connect } from "react-redux"
import { logoutUser } from "../actions"
import { db, storage } from "../services/firebase"
import MenuAppBar from "./Header"
import MyTable from "./Table"
// import data from '../masterjson1.json'
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { addDoc, collection, doc, updateDoc } from "firebase/firestore"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import ImageMapper from "react-image-mapper"
import "./ImageDetail.css"
import { CircularProgressWithLabel } from "./ProgressBar"
import { useLocation, useNavigate } from "react-router-dom"

/// random numbers
const digit = () => `${Math.round(Math.random() * 255)}`
const colorArray = (length) => {
  let list = []
  for (let i = 0; i < length; i++) {
    //convert to base 16
    let hex = digit().toString(16)

    //check if length is 2, if not pad with 0
    hex = "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")
    console.log(hex)
    //push hex
    list.push(hex)
  }
  return list
}
let imageMaper = createRef()
let input = createRef()

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "0px auto",
    marginTop: "20px",
    marginBottom: "60px",
    flexWrap: "wrap",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  imgDiv: {
    cursor: "pointer",
  },
  selected: {
    border: "5px solid red",
  },
  gridList: {
    width: "40%",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  img: {
    boxShadow: "0 0 10px black",
  },
  ml2: {
    marginLeft: "15px",
  },
  buttonMain: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: -1,
  },
  buttonsDiv: {
    width: "max-content",
    margin: "0  auto 2em",
    textAlign: "right",
    zIndex: 2,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}))

// export async function getMeta(url) {
//   var img = new Image()
//   img.src = url
//   await img.onload
//   return img
// }

function ImageDetail({
  dispatch,
  // history,
  images,
  // location: { state },
  user,
  ...props
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()

  const [coordinates, setCoordinates] = useState({ x: "", y: "" })

  let { image } = location.state
  if (!image.url) navigate(-1)

  const myList = collection(db, `images/${user.uid}/images`)
  const userStorageRef = ref(storage, `images/${user.uid}`)

  const [localJson, SetLocalJson] = useState({})
  const [stateObj, setStateObj] = useState(null)
  const [progress, setProgress] = useState(0)
  const [clickSave, setClickSave] = useState(false)

  const toggleSave = () => {
    setClickSave(!clickSave)
  }
  const handleLogout = () => {
    dispatch(logoutUser())
  }
  const handleChange = async (e) => {
    if (e.target.files[0]) {
      let jsonfile = e.target.files[0]
      var reader = new FileReader()
      reader.readAsText(jsonfile)
      reader.onload = (event) => {
        console.log(event.target.result)
        let jsonData = event.target.result

        const storageRef = ref(userStorageRef, `${image.key}/${jsonfile.name}`)
        const uploadTask = uploadBytesResumable(storageRef, jsonfile)

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // progrss function ....
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            setProgress(progress)
          },
          (error) => {
            // error function ....
            console.log(error)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              const dataObj = {
                ...image,
                jsonData,
                json: url,
                jsonName: jsonfile.name,
              }
              updateDoc(doc(myList, image.key), dataObj).then((d) => {
                console.log("File available at", d)
                if (input) input.value = null

                navigate("image", { state: { image: dataObj }, replace: true })

                stateUpdate(jsonData)
                setProgress(0)
              })
                .catch((err) => {
                  console.log("Error at", err)
                })
            })
          }
        )
      }
    }
  }
  const stateUpdate = (jsonData) => {
    if (jsonData) image = { ...image, jsonData }
    let imgWidth = image.width // image actual
    const showingWidth = window.innerWidth - 20 // setting aspect Image VIew area;
    let data = image.jsonData ? JSON.parse(image.jsonData) : {}
    const colors = colorArray(Object.keys(data).length) // getting random color according to the object size;
    const MAP = {
      name: "my-map",
      areas: [
        ...Object.keys(data).map((v, ind, { length }) => {
          return {
            name: v,
            shape: "poly",
            coords: data[v].reduce((arr, item) => [...arr, ...item], []),
            fillColor: colors[ind % length] + "aa",
            originalCoords: data[v],
            strokeColor: colors[ind % length],
            active: true,
          }
        }),
      ],
    }
    setStateObj({
      colors,
      MAP,
      imgWidth,
      showWidth: showingWidth < imgWidth ? showingWidth : imgWidth,
    })
  }
  useEffect(() => {
    window.addEventListener("resize", () => stateUpdate())
    stateUpdate()
    return () => {
      window.removeEventListener("resize", () => stateUpdate())
    }
  }, [image.url])

  const clickImage = (evt) => {
    const { imgWidth, showWidth } = stateObj
    const ratio = imgWidth / showWidth
    var x = evt.nativeEvent.layerX * ratio
    var y = evt.nativeEvent.layerY * ratio
    x = Math.round(x)
    y = Math.round(y)
    setCoordinates({ x, y })

    addDoc(collection(myList, image.key, 'points'), { x, y })
      .then(() => {
        console.log("Points Sucessfully Added!")
      }).catch(() => {
        console.log("Error Adding Point!")
      })
  }
  const onMapperClick = function (area, index, event) {
    clickImage(event)
    let ishas = imageMaper.props.map.areas[index].preFillColor
    if (!ishas) {
      SetLocalJson({
        ...localJson,
        [area.name]: area.originalCoords,
      })
      imageMaper.props.map.areas[index].preFillColor = area.fillColor
    } else {
      delete localJson[area.name]
      SetLocalJson({ ...localJson })
      imageMaper.props.map.areas[index].preFillColor = null
    }
  }
  return (
    <>
      <MenuAppBar
        onLogout={handleLogout}
        isBack
        title={image.name}
      />
      <div className={classes.root + " image-main"}>
        {!!stateObj && (
          <ImageMapper
            ref={(r) => (imageMaper = r)}
            src={image.url}
            map={stateObj.MAP}
            lineWidth={2}
            onLoad={() => { }}
            imgWidth={stateObj.imgWidth}
            width={stateObj.showWidth}
            onClick={onMapperClick}
            onImageClick={clickImage}
          />
        )}
      </div>

      <div style={{ zIndex: 9, position:'relative' }}>
        {!!progress > 0 && <CircularProgressWithLabel value={progress} />}
        <Button
          component="label"
          variant="contained"
          color="info"
          className={classes.button}
          startIcon={<CloudUploadIcon />}
        >
          Upload
          <input
            accept="application/JSON"
            ref={(r) => (input = r)}
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleChange}
          />
        </Button>
        <Button
          onClick={toggleSave}
          style={{ float: "right" }}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Save
        </Button>
      </div>
      <div className="json-block">
        {clickSave &&
          Object.keys(localJson).map((v, ind) => (
            <p key={ind + "points in json"}>
              {" "}
              {v + " : " + JSON.stringify(localJson[v])}{" "}
            </p>
          ))}
      </div>
      <div style={{ height: "60px" }} />
      <div className={classes.buttonMain}>
        <div className={classes.buttonsDiv}>
          <MyTable coordinates={coordinates} />
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    images: state.home.images,
  }
}

export default connect(mapStateToProps)(ImageDetail)
