import React from "react"
import { connect } from "react-redux"
import { logoutUser } from "../actions"
import MenuAppBar from "./Header"
import ImageUpload from "./ImageUpload"

function Home(props) {

  const handleLogout = () => {
    const { dispatch } = props
    dispatch(logoutUser())
  }

  const { isLoggingOut, logoutError, user } = props

  return (
    <div>  
      <MenuAppBar onLogout={handleLogout} />
      {/* <h1>This is your app's protected area.</h1>
      <p>Any routes here will also be protected</p>
      <button onClick={this.handleLogout}>Logout</button> */}
      {isLoggingOut && <p>Logging Out....</p>}
      {logoutError && <p>Error logging out</p>}
      {!!user?.uid && <ImageUpload {...props} />}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    user: state.auth.user,
    logoutError: state.auth.logoutError,
  }
}

export default connect(mapStateToProps)(Home)
