import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Navigate, Route, Routes } from "react-router-dom"
import { collection, onSnapshot } from "firebase/firestore"
import { setImages } from "./actions"
import Home from "./components/Home"
import ImageDetail from "./components/ImageDetail"
import Login from "./components/Login"
import { db } from "./services/firebase"
import { Loader } from "./components/Loader"

function App(props) {
  const { isAuthenticated, isVerifying, user, settingImages } = props

  useEffect(() => {
    if (user.hasOwnProperty("uid")) {
      const myList = collection(db, `images/${user.uid}/images`)

      const myListSubscription = onSnapshot(myList, (querySnapshot) => {
        let images = []
        querySnapshot?.forEach((doc) => {
          images.push(doc.data())
        })
        console.log(images)
        settingImages(images)
      })

      return () => {
        if (myListSubscription) myListSubscription()
      }
    }
  }, [user, settingImages])

  if(isVerifying) {
    return <Loader />
  }

  return (
    <Routes>
      {isAuthenticated && (
        <>
          <Route
            path="/"
            element={
              <Home />
            }
          />
          <Route
            path="/image"
            element={
              <ImageDetail />
            }
          />
        </>
      )}
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    settingImages: (images) => dispatch(setImages(images)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
