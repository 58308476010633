import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import makeStyles from "@mui/styles/makeStyles"
import React, { useEffect, useState } from "react"
// import GridList from '@mui/material/GridList';
// import GridListTile from '@mui/material/GridListTile';
// import GridListTileBar from '@mui/material/GridListTileBar';
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import ImageDD from "./ImageSelectDD"
// import withWidth from "@mui/material/withWidth"
// import { compose } from "redux"
import { useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

const $ = window.$

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "90%",
    margin: "0px",
    marginTop: "20px",
    minHeight: "auto",
    height: "68%",
    maxHeight: "62vh",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    overflowY: "auto",
    boxShadow: "0 0 5px black",
    backgroundColor: theme.palette.background.paper,
  },
  imgDiv: {
    cursor: "pointer",
    overflow: "hidden",
  },
  selected: {
    border: "5px solid" + theme.palette.primary.main,
  },
  gridList: {
    width: "40%",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  img: {
    // transition: "all .2s ",
  },
  ml2: {
    marginLeft: "15px",
  },
  buttonsDiv: {
    width: "90%",
    margin: "3% 0",
    textAlign: "right",
  },
 
  ImgListRoot:{
    overflow:"hidden",
  },
  imagBox:{
    overflow:"hidden",
    margin:"0px"
  },
  ImgListImg:{
    height:'inherit !important'
  },
  // OKButton:{
  //   backgroundColor:'red'
  // }
}))

function ImageDownload({ images, __width, __history, deleteImage = () => { } }) {
  
  const navigate = useNavigate()
  const classes = useStyles()
  const theme = useTheme()

  const [selected, setSelected] = useState(null)
  
  const smDevice = useMediaQuery(theme.breakpoints.up("sm"))
  const mdDevice = useMediaQuery(theme.breakpoints.up("md"))
  const lgDevice = useMediaQuery(theme.breakpoints.up("lg"))

  const changeSelected = (image) => {
    setSelected(selected?.name === image.name ? null : image)
  }
  const resizing = () => {
    const li = $(".Images-grid .MuiGridListTile-root")
    li.height(li.width())
    const parentWidth = li.width()
    // const parentHeight = li.height()
    const images = li.find(".images-div")

    images.each(function () {
      
      const { width, height } = $(this).data()
      // console.log("resizing", parentHeight, parentWidth, width, height)

      if (width > height) {
        $(this).height(parentWidth)
        $(this).width("auto")
      } else {
        $(this).width(parentWidth)
        $(this).height("auto")
      }
    })
  }

  // let columns = 4;
  let columns = lgDevice ? 4 : mdDevice ? 3 : smDevice ? 2 : 1
  // let columns = width === "xs" ? 2 : width === "sm" ? 3 : 4

  window.addEventListener("resize", resizing)
  useEffect(() => {
    return () => window.removeEventListener("resize", resizing)
  }, [])

  return (
    <>
      <div className={classes.root}>
        <ImageList
          onLoad={resizing}
          style={{ width: "100%" }}
          className={classes.imagBox}
          cols={columns}
          rowHeight={184}
        >
          {images.map((image) => (
            <ImageListItem
              classes={{
                root : classes.ImgListRoot,
                img: classes.ImgListImg
                // imgFullHeight: classes.imgFullHeight,
              }}
              style={{ cursor: "pointer", position: "relative" }}
              key={image.name}
              className={selected?.name === image.name ? classes.selected : ""}
            >
              <ImageDD onDelete={() => deleteImage(image)} />
              <img
                onClick={() => changeSelected({ ...image })}
                src={image.url}
                data-width={image.width}
                data-height={image.height}
                className={classes.img + " images-div" }
                alt={image.name}
              />
              <ImageListItemBar
                onClick={() => changeSelected({ ...image })}
                title={image.name}
                actionIcon={
                  <IconButton
                    aria-label={`info about ${image.name}`}
                    className={classes.icon}
                  />
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
      <div className={classes.buttonsDiv}>
        <Button
          className={classes.ml2}
          disabled={!selected}
          onClick={() => setSelected(null)}
          color="error"
        >
          CANCEL
        </Button>
        <Button
          className={classes.ml2}
          classes={{root:classes.OKButton}}
          disabled={!selected}
          onClick={() => navigate('image', {
            state: {
              image: selected
            }
          })}
          variant="contained"
          
          color="primary"
        >
          OKAY
        </Button>
      </div>
    </>
  )
}

export default ImageDownload