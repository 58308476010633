import { initializeApp } from "firebase/app"

// import "firebase/auth"
// import "firebase/firestore"
// import "firebase/storage"
// import "firebase/database"

import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

// const firebaseConfig = {
//   apiKey: "AIzaSyDiALxMB3kGK_2YBrilCGrhEDjfvx0bn6A",
//   authDomain: "touchup-3710c.firebaseapp.com",
//   databaseURL: "https://touchup-3710c.firebaseio.com",
//   projectId: "touchup-3710c",
//   storageBucket: "touchup-3710c.appspot.com",
//   messagingSenderId: "272230880209",
//   appId: "1:272230880209:web:d97ddf9b283b0c9c3f46f0",
//   measurementId: "G-V3V6JZD68D"
// };

// var firebaseConfig = {
//   apiKey: "AIzaSyA4rHZRWAnCfcwKw3SRBuMMchA__i0JrB4",
//   authDomain: "javascript-a4cec.firebaseapp.com",
//   databaseURL: "https://javascript-a4cec.firebaseio.com",
//   projectId: "javascript-a4cec",
//   storageBucket: "javascript-a4cec.appspot.com",
//   messagingSenderId: "922585900523",
//   appId: "1:922585900523:web:46a8e59b72d2b87a4225ae"
// };

// Client Credentials
const firebaseConfig = {
  apiKey: "AIzaSyApoAJ5-DoLGGOqkvKilz7Q4X6fLDoGjUM",
  authDomain: "fir-login-app-c1a24.firebaseapp.com",
  projectId: "fir-login-app-c1a24",
  storageBucket: "fir-login-app-c1a24.appspot.com",
  messagingSenderId: "553253998527",
  appId: "1:553253998527:web:881b16a6554f683ac49b0a",
  measurementId: "G-N22RG2E7D9",
}

// Initialize Firebase
export const myFirebase = initializeApp(firebaseConfig)
export const auth = getAuth(myFirebase)
export const db = getFirestore(myFirebase)
export const mdb = getDatabase(myFirebase)

// export const db = baseDb
export const storage = getStorage(myFirebase)
