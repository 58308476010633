import makeStyles from "@mui/styles/makeStyles"
import React, { useState } from "react"
import { connect } from "react-redux"
import { loginUser } from "../actions"
import Mob from "./Assets/TouchUp-min_empty.png"
import Scr3 from "./Assets/beach_nobackground.png"
import Scr4 from "./Assets/cammel_nobackground.png"
import Scr2 from "./Assets/dog_in_tux.png"
import Scr1 from "./Assets/girl2.png"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import clsx from "clsx"
import { Navigate } from "react-router-dom"
import { Fade } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import Ap from "./Assets/app.png"
import Touch from "./Assets/touchUp.png"
import Foot from "./FooterPage"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#fff",
      fontFamily: "Roboto",
    },
  },
  or: {
    position: "relative",
    zIndex: "1",
    "&::before": {
      borderTop: "2px solid #dfdfdf",
      content: '""',
      margin: "0 auto",
      position: "absolute",
      top: "50%",
      left: "0",
      right: "0",
      bottom: "0",
      width: "95%",
      zIndex: "-1",
    },
  },

  screen: {
    position: "absolute",
    // marginLeft: "8.2px",
    width: "260px",
    // height: "571.0px",
    margin: "0.0px",
    right: "45px",
    top:"39px",
    overflow:"hidden"

  },

  btn: {
    textTransform: "none !important",
  },
  border: {
    boxShadow: "none",
    border: "3px solid RGBA(208, 208, 208,0.5)",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cont: {
    maxWidth: "860px",
    margin: "auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    "@media (max-width:999px)": {
      maxWidth: "360px !important",
    },
  },
  im: {
    "@media (max-width:999px)": {
      display: "none",
    },
  },
  form: {
    zIndex: "2",
    margin: "0px",
    padding:"10px 0px",
    display:"flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height:"600px",
    "@media (max-width:999px)": {
      maxWidth: "100% !important",
    },
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    textAlign: "center",
  },
}))

function Login(props){

  const classes = useStyles()

  const [email, setEmail ] = useState("")
  const [password, setPassword ] = useState("")

  const handleEmailChange = ({ target }) => {
    setEmail(target.value)
  }
  const handlePasswordChange = ({ target }) => {
    setPassword(target.value)
  }
  const handleSubmit = () => {
    const { dispatch } = props
    dispatch(loginUser(email, password))
  }

  const { loginError, isAuthenticated } = props

  if (isAuthenticated) {
    return <Navigate to="/" />
  } else {
    return (
      <Grid item style={{ height: "100vh" }}>
        <Grid container className={classes.cont}>
          <Grid
            style={{ marginTop: "0px", position:"relative" }}
            md={7}
            item
            className={clsx(classes.paper, classes.im)}
          >
            <div>
              <img
                style={{ maxWidth: "120%" }}
                src={Mob}
                alt="google pixel phone with rainbow hair girl on screen"
              ></img>
            </div>
            <div className={clsx(classes.screen, "slide-container")}>
              <Fade
                className={classes.screen}
                arrows={false}
                autoplay={true}
                infinite={true}
                duration={3000}
              >
                <div className="each-fade">
                  <div className="image-container">
                    <img
                      style={{ height: "540px" }}
                      alt="first"
                      src={Scr1}
                    />
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img
                      style={{ height: "540px" }}
                      alt="second"
                      src={Scr2}
                    />
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img
                      style={{ height: "540px" }}
                      alt="third"
                      src={Scr3}
                    />
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img
                      style={{ height: "540px" }}
                      alt="fourth"
                      src={Scr4}
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </Grid>

          <Grid
            item
            className={classes.form}
            md={5}
          >
          <div>
            <Paper
              className={clsx(classes.paper, classes.border)}
              style={{ padding: "10px 40px" }}
            >
              <div style={{ maxWidth: "70%" }}>
                <img
                  src={Touch}
                  style={{ maxWidth: "100%", margin: "15px 0" }}
                  alt="Company logo"
                ></img>
              </div>

              <TextField
                variant="outlined"
                fullWidth
                size="small"
                inputProps={{
                  style: {
                    fontSize: 13,
                    fontWeight: "bold",
                    marginTop: "5px",
                    height: "50%",
                    padding: "8px 14px",
                  },
                }}
                id="email"
                placeholder="Username"
                name="email"
                onChange={handleEmailChange}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                size="small"
                inputProps={{
                  style: {
                    fontSize: 13,
                    fontWeight: "bold",
                    marginTop: "5px",
                    padding: "8px 14px",
                  },
                }}
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                onChange={handlePasswordChange}
              />
              {loginError && (
                <Typography component="p" className={classes.errorText}>
                  Incorrect email or password.
                </Typography>
              )}
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  margin: "15px 0",
                  backgroundColor: "RGB(34, 162, 235)",
                  maxHeight: "30px",
                }}
                className={clsx(classes.submit, classes.btn)}
                onClick={handleSubmit}
              >
                <strong>Log In</strong>
              </Button>
              <h4
                style={{
                  color: "gray",
                  marginTop: "0px",
                  marginBottom: "15px",
                  width: "100%",
                  textAlign: "center",
                }}
                className={classes.or}
              >
                <span style={{ background: " #fff", padding: "0 15px" }}>
                  PRIVATE
                </span>
              </h4>
              <Button
                href=""
                color="primary"
                style={{ color: "RGB(57, 85, 159)" }}
                className={classes.btn}
              >
                <strong>CLOSED ACCESS</strong>
              </Button>
              <Button
                href=""
                color="primary"
                style={{
                  color: "RGB(57, 85, 159)",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
                className={classes.btn}
              >
                <strong>Forgot password: Contact Admin</strong>
              </Button>
            </Paper>
            <Paper
              className={clsx(classes.paper, classes.border)}
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "10px",
                padding: "10px 0",
              }}
            >
              <p style={{ margin: "0", fontSize: "14px" }}>
                <strong>Don't have an account?</strong>
              </p>
              <Button
                href=""
                color="primary"
                style={{ color: "RGB(34, 162, 235)" }}
                className={classes.btn}
              >
                Contact Admin
              </Button>
            </Paper>
            </div>
            <div
              style={{ padding: "5px", fontSize: "14px" }}
              className={classes.paper}
            >
              <p className={classes.btn}>
                <strong> </strong>
              </p>
              <img
                style={{ maxWidth: "300px" }}
                src={Ap}
                alt="coming soon to the app store"
              ></img>
            </div>
          </Grid>
        </Grid>
        <Foot />
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default (connect(mapStateToProps)(Login))
