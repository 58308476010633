import { HOMEACTIONS } from "../actions/"
const initialState = {
  images: [],
}
export default function homeReducer(state = initialState, { type, payload }) {
  switch (type) {
    case HOMEACTIONS.SET_IMAGES:
      return {
        ...state,
        images: payload,
      }
    default:
      return state
  }
}
