import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Fab from "@mui/material/Fab"
import blue from "@mui/material/colors/blue"
import { collection, deleteDoc, doc, setDoc } from "firebase/firestore"
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import { db, storage } from "../services/firebase"
import ImageDownload from "./ImageDownload"
import { CircularProgressWithLabel } from "./ProgressBar"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  fabbutton: {
    color: blue[900],
    margin: 10,
  },
}))


function ImageUpload(props) {

  const {user, images} = props

  const classes = useStyles()
  const inputRef = useRef(null)

  const [image, setImage ] = useState(null)
  // const [url, setUrl ] = useState("")
  // const [images, setImages] = useState([])
  const [progress, setProgress] = useState(0)

  const userId = user.uid
  const userStorageRef = ref(storage, `images/${userId}`)
  const myList = collection(db, `images/${userId}/images`)

  const handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0]
      setImage(image)
    }
  }
  const handleUpload = () => {
    // const { image } = this.state
    let name = image.name.replace(/\./g, "_").replace(/ /g, "")
    const storageRef = ref(userStorageRef, `${name}/${image.name}`)
    const uploadTask = uploadBytesResumable(storageRef, image)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        setProgress(progress)
        // this.setState({ progress })
      },
      (error) => {
        // error function ....
        console.log(error, 'File Upload Error!')
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          var img = new Image()
          img.src = url
          img.onload = async (d) => {
            const { width, height } = img

            const dataObj = {
              name: image.name,
              key: name,
              url,
              width,
              height,
            }

            setDoc(doc(myList, name), dataObj).then(_ => {
              if (inputRef.current) inputRef.current = null;
              setImage(null)
              setProgress(0)
            }).catch(err => {
              console.log('File available at', err);
            })
          }
        })
      })
  }
  const deleteImage = async (image) => {
    if (image) {
      let name = image.key
      const storageRef = ref(userStorageRef, `${name}/${image.name}`)
      const jsonStorageRef = ref(userStorageRef, `${name}/${image.jsonName}`)

      deleteObject(storageRef).then(() => {
        console.log("Storage File Successfully Delete!")
        deleteDoc(doc(myList, name))
          .then(async () => {
            if (image.hasOwnProperty("json")) {
              deleteObject(jsonStorageRef).then(() => {
                console.log("JSON Storage File Successfully Delete!")
              }).catch (() => {
                console.log("Error Deleting JSON Storage File!")
              })
            }
            console.log("Firestore Document Successfully Deleted!")
          }).catch((error) => {
            console.log("Error Deleting Firestore Document!")
          });
      }).catch((error) => {
        console.log("Error Deleting Storage File!")
      });
    }
  }

  const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }

  return (
    <div style={style}>
      <Box component="span" m={2}>
        <input
          accept="image/*"
          ref={(r) => (inputRef.current = r)}
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={handleChange}
        />
        <label htmlFor="contained-button-file">
          <Fab component="span" className={classes.fabbutton}>
            <AddPhotoAlternateIcon />
          </Fab>
        </label>
        {!!image && <CircularProgressWithLabel value={progress} />}
        <Button
          onClick={handleUpload}
          variant="contained"
          disabled={!image}
          color="primary"
          className={classes.button}
          startIcon={<CloudUploadIcon />}
        >
          Upload
        </Button>
      </Box>

      <ImageDownload
        {...props}
        deleteImage={deleteImage}
        images={images}
      />
    </div>
  )
}

export default connect((state) => ({
  user: state.auth.user,
  images: state.home.images,
}))(ImageUpload)
