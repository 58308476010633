export const HOMEACTIONS = {
  SET_IMAGES: "SET-IMAGES-REQUEST",
}

export const SettingImages = (data) => ({
  type: HOMEACTIONS.SET_IMAGES,
  payload: data,
})

export function setImages(images) {
  return (dispatch) => {
    dispatch(SettingImages(images))
    return true
  }
}
