import Button from "@mui/material/Button"
import React from "react"
import "./FooterPage.css"

function FooterPage() {
  return (
    <div className="Row">
      <Button
        href=""
        color="primary"
        style={{
          color: "RGB(57, 85, 159)",
          fontSize: "12px",
          marginTop: "5px",
        }}
      >
        <strong>Privacy</strong>
      </Button>
      <Button
        href=""
        color="primary"
        style={{
          color: "RGB(57, 85, 159)",
          fontSize: "12px",
          marginTop: "5px",
        }}
      >
        <strong>Cookie</strong>
      </Button>
      <Button
        href=""
        color="primary"
        style={{
          color: "RGB(57, 85, 159)",
          fontSize: "12px",
          marginTop: "5px",
        }}
      >
        <strong>Internal</strong>
      </Button>
      <Button
        href=""
        color="primary"
        style={{
          color: "RGB(57, 85, 159)",
          fontSize: "12px",
          marginTop: "5px",
        }}
      >
        <strong>Terms</strong>
      </Button>
    </div>
  )
}

export default FooterPage
